import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

function Services(props) {
  return (
    <Layout>
      <SEO
        title="Services"
        keywords={[`gortek`, `app`, `startup`, `business`]}
      />

      <h2 className="text-primary">Services</h2>

      <div className="flex md:flex-row flex-col text-center">
        <Link to="services/startups" className="md:w-1/2 m-3 p-3 shadow rounded bg-white">
          <img alt="Launch your idea!" title="Launch your idea!" src={require(`../../images/startup.svg`)} />
          <h6 className="font-bold mt-8">
            For Startups
          </h6>
          <p className="text-sm text-gray-600 white-space-no-wrap truncate">
            We build your idea
          </p>
        </Link>

        <Link to="services/small-businesses" className="md:w-1/2 m-3 p-3 shadow rounded bg-white">
          <img alt="Grow your business!" title="Grow your business!" src={require(`../../images/business.svg`)} />
          <h6 className="font-bold mt-8">
            For Small Businesses
          </h6>
          <p className="text-sm text-gray-600 white-space-no-wrap truncate">
            We help you grow
          </p>
        </Link>
      </div>

    </Layout>
  )
}

export default Services
